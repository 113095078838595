import { useCallback, useContext } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Header from "../../components/Header";
import ScrollToTop from "../../components/ScrollToTop";
import { NavContext } from "../../context/NavProvider";
import { Helmet } from "react-helmet";
import circle from "./circle.module.scss"
import Footer from "../../components/Footer";

export default function Circle(): JSX.Element {

    const navbar = useContext(NavContext)
    navbar?.setActive('')

    const handleApplication = useCallback(() => {}, [])
    
    return  <>
                <Helmet>
                    <meta name="theme-color" content="#050F12" />
                </Helmet>
                <ScrollToTop />
                <Header title="Willkommen im Circle." subtitle="Jeder Circle besteht aus Punkten." invert className={circle.Header} classNameNav={circle.FadeInAfter + circle.nav} relative />
                <section style={{padding: "3rem 0"}} className="bg-black text-light">
                    <Container className={circle.FadeInAfter}>
                        <Row>
                            <Col lg={{span: 6, offset: 3}} className="text-center">
                                <p className="h3">Der UNISONO Circle</p>
                                <p className="lead">
                                    Mitglieder, die gerne dabei sind, wenn etwas aufgebaut wird, können ihre 
                                    besonderen Fähigkeiten im UNISONO Circle einsetzen. Zeig was Du kannst. 
                                    Bist Du ein Organisationstalent, hast Freude, Neues aufzubauen und bist 
                                    ein Teamplayer?
                                </p>
                                <p>
                                    <Button variant="link" className="link-primary" onClick={handleApplication}>Bewirb Dich jetzt</Button>
                                </p>
                            </Col>
                        </Row>
                        <section className={circle.SectionContainer}>
                            <Section title="Voraussetzungen">
                                <p>Um Teil des UNISONO Circle zu werden, musst Du diese Voraussetzungen erfüllen:</p>
                                <ul>
                                    <li>Du bist UNISONO Alumni Mitglied oder Deine Anmeldung als UNISONO Alumni Mitglied wird gerade überprüft.</li>
                                </ul>
                            </Section>
                            <Section title="Deine Bewerbung">
                                <p>
                                    Melde Dich bei uns mit einem kurzem Text, der Dich und Deine Person beschreibt.
                                    Versuche dabei auf folgende Punkte einzugehen.
                                </p>
                                <ul>
                                    <li>Angaben zu Deiner Person und Ausbildung</li>
                                    <li>Eine Erfahrung während Deiner Zeit bei den Wiener Sängerknaben, die Dich besonders geprägt hat</li>
                                    <li>Deine Motivation, Mitglied des UNISONO Circle zu werden</li>
                                    <li>Besondere Eigenschaften oder Fähigkeiten</li>
                                </ul>
                                <p>Füge außerdem folgende Informationen bei:</p>
                                <ul>
                                    <li>Deine E-Mail-Adresse, mit der Du bei UNISONO registriert bist. 
                                        Wir können mit Dir nur über diese E-Mail-Adresse kommunizieren.</li>
                                    <li>Angaben zu Deiner Zeit bei den Wiener Sängerknaben (Jahreszahlen genügen)</li>
                                </ul>
                            </Section>
                            <Section title="Bewerbung einreichen">
                                <p>Sende eine E-Mail mit Deinem Text an <a href="mailto:vorstand@unisono-alumni.at">vorstand@unisono-alumni.at</a>.</p>
                            </Section>
                        </section>
                        <section className={circle.SectionContainer}>
                            <Row className={circle.Section}>
                                <Col>
                                    <p className="text-white-50 small">
                                        Bitte beachte, dass wir Dir keinen Anspruch auf eine Aufnahme in den UNISONO 
                                        Circle geben können und Deine Bewerbung ohne Angaben von Gründen ablehnen können. 
                                        Im Falle einer Aufnahme können Du oder UNISONO einseitig und ohne Angaben von
                                        Gründen die Zusammenarbeit beenden. Wenn Du Deine Bewerbung abschickst, stimmst 
                                        Du diesen Bestimmungen zu.
                                    </p>
                                </Col>
                            </Row>
                        </section>
                    </Container>
                </section>
                <Footer dark />
            </>
}

const Section = (props: React.PropsWithChildren<{title: string}>) : JSX.Element => {

    return  <Row className={circle.Section}>
                <Col md="4">
                    <h3>{props.title}</h3>
                </Col>
                <Col md="8">
                    {props.children}
                </Col>
            </Row>
}
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop(): JSX.Element {

    const path = useLocation().pathname

    useEffect(() => {
        window.scrollTo({
            left: 0, 
            top: 0, 
            behavior: "smooth"
        })
    }, [path]);

    return <></>
}

import React, { useEffect, createRef, useContext } from 'react';
import useOnScreen from './useOnScreen';
import { NavContext } from '../context/NavProvider';

export default function useNavSpy<T extends HTMLElement = HTMLDivElement>(navLinkId: string, ref: React.RefObject<T> = createRef<T>()) {
	
	const context = useContext(NavContext);

	const isOnScreen = useOnScreen(ref);

	useEffect(() => {
		if (isOnScreen) {
			context?.setActive(navLinkId)
		}
	}, [isOnScreen, navLinkId, context]);

	return ref;
};
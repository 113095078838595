import useNavSpy from "../../hooks/useNavSpy"
import logo from "../../assets/logo/UNISONO_TextLogo_96_md.png"

import "./styles.scss"
import { SectionProps } from "."
import { useParallax } from "react-scroll-parallax"
// import { createRef, useRef } from "react"

export default function HomeHero(props: SectionProps) : JSX.Element {

    // const myRef = useRef<HTMLDivElement>(null)
    // if (typeof props.ref === "function") return <></>
    const homeRef = useNavSpy("index", props.toRef)

    const overlayParallax = useParallax<HTMLDivElement>({
        speed: 10
    })
    const logoParallax = useParallax<HTMLImageElement>({
        speed: 25
    })
    const headingParallax = useParallax<HTMLParagraphElement>({
        speed: 20
    })
    const btnParallax = useParallax<HTMLDivElement>({
        speed: 15
    })

    return  <div id="home" ref={homeRef}>
                <div className="bg-overlay bg-overlay-notes">&nbsp;</div>
                <div className="mx-3 text-center" style={{maxWidth: "800px", padding: "1rem", width: "100%", transform: "translateY(-25%)"}} ref={overlayParallax.ref}>
                    <img src={logo} alt="UNISONO Logo" className="mb-3" style={{maxWidth: "100%"}} ref={logoParallax.ref} />
                    <p className="h1 fw-bold" style={{fontSize: "200%"}} ref={headingParallax.ref}>Dein Netzwerk für<br />Deine Zukunft.</p>
                    <div className="d-grid py-4" style={{margin: "0 auto", maxWidth: "250px"}} ref={btnParallax.ref}>
                        <a href="https://club.unisono-alumni.at/" className="btn btn-cta btn-lg">Mitglied werden</a>
                    </div>
                </div>
            </div>
}
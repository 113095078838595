import { useContext } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ScrollToTop from "../../components/ScrollToTop";
import { NavContext } from "../../context/NavProvider";

export default function Statutes(): JSX.Element {

    const navbar = useContext(NavContext)
    navbar?.setActive('')
    
    return  <>
            <ScrollToTop />
            <Header title="Statuten" relative />
            <section className="pt-4">
                <div className="container mt-4">
                <div className="row mb-4">
                    <div className="col-12">
                    <h2>Statuten des Vereins</h2>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                    <h3>§ 1: Name, Sitz und Tätigkeitsbereich
                    </h3>
                    <p>(1) Der Verein führt den Namen „unisono – Verein der Absolventen und Absolventinnen und der freiwilligen Mitarbeiter und Mitarbeiterinnen der Institution der Wiener Sängerknaben".</p>
                    <p>(2) Er hat seinen Sitz in 1020 Wien, Obere Augartenstraße 1c, und erstreckt seine Tätigkeit auf Österreich.</p>
                    <p>(3) Die Errichtung von Zweigvereinen ist nicht beabsichtigt.</p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                    <h3>§ 2: Zweck
                    </h3>
                    <p>Der Verein, dessen Tätigkeit nicht auf Gewinn gerichtet ist, bezweckt die Aufrechterhaltung der Verbindung und Beziehungspflege zu ehemaligen Schülern („Alumni") der Wiener Sängerknaben. Weiterer Vereinszweck ist die Förderung und Unterstützung
                        der Arbeit der Wiener Sängerknaben durch freiwillige Mitarbeit („Backstage Chor") oder Leistung finanzieller Beiträge. Insbesondere soll durch die freiwillige Mitarbeit die Organisation und Abwicklung hochwertiger Veranstaltungen und Aufnahmen
                        musikalischer Darbietungen der Wiener Sängerknaben wesentlich gefördert werden.</p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                    <h3>§ 3: Mittel zur Erreichung des Vereinszwecks
                    </h3>
                    <p>(1) Der Vereinszweck soll durch die in den Abs. 2 und 3 angeführten ideellen und materiellen Mittel erreicht werden.</p>
                    <p>(2) Als ideelle Mittel dient die freiwillige (unentgeltliche) Mitarbeit der ordentlichen und außerordentlichen Mitglieder zur Unterstützung der Wiener Sängerknaben in den Bereichen
                        <ol className="ml-4">
                        <li>Archiv (Aufbau und Verwaltung)</li>
                        <li>Betreuung der Wiener Sängerknaben (Lernhilfe, Unterstützung bei der Freizeitgestaltung)</li>
                        <li>Empfang (Begrüßung, Registrierung, Garderobe)</li>
                        <li>Eventbetreuung (Organisation, Technik, Projektmanagement)</li>
                        <li>Führungen</li>
                        <li>Fundraising</li>
                        <li>Korrepetition</li>
                        <li>Künstlerische Produktionen (Ideenentwicklung, Gestaltung, Proben, Aufführungen)</li>
                        <li>Merchandising (Produktorganisation, Beschaffung, Lagerhaltung, Verkauf, Werbung)</li>
                        <li>Musikarchiv (Noten, Instrumente, Hilfsmittel)</li>
                        <li>Office Support (Assistententätigkeit, Dokumentation, Ablage, Abrechnung)</li>
                        <li>Schneiderei und Kostümierung (Kreation, Neuschaffung, Reparatur, Instandsetzen)</li>
                        </ol>
                    </p>
                    <p>Jedes ordentliche und jedes außerordentliche Mitglied verpflichtet sich mit Vereinsbeitritt dazu, den Vereinszweck durch freiwillige, unentgeltliche persönliche Mitarbeit oder ersatzweise finanzielle Leistungen (Mitgliedsbeitrag) zu fördern. Wer
                        die jeweilige Mindestleistung pro Jahr erbracht hat, ist von der Verpflichtung zur Leistung finanzieller Beiträge befreit. Die jeweilige präzise Aufgabenbeschreibung und nähere Ausgestaltung der Leistungsverpflichtung ordentlicher und
                        außerordentlicher Mitglieder wird durch den Vorstand festgesetzt. Die Festsetzung und eine allfällige Erhöhung des von den ordentlichen Mitgliedern zu erbringenden Mindestleistungsumfangs obliegt der Generalversammlung auf Antrag des Vorstandes.</p>
                    <p>(3) Die erforderlichen materiellen Mittel sollen aufgebracht werden durch
                        <ol className="ml-4">
                        <li>Mitgliedsbeiträge ordentlicher Mitglieder,</li>
                        <li>Beitrittsgebühren und Mitgliedsbeiträge der außerordentlichen Mitglieder,</li>
                        <li>Öffentliche Subventionen,</li>
                        <li>Spenden,</li>
                        <li>Sponsoring.</li>
                        </ol>
                    </p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                    <h3>§ 4: Arten der Mitgliedschaft
                    </h3>
                    <p>(1) Die Mitglieder des Vereins gliedern sich in ordentliche, außerordentliche, und Ehrenmitglieder.</p>
                    <p>(2) Ordentliche Mitglieder können nur ehemalige Schüler werden, die sich zur physischen Mitarbeit an der Vereinsarbeit oder Zahlung eines Mitgliedsbeitrags in dem von der Generalversammlung festgesetzten Umfang verpflichten. („Alumni")</p>
                    <p>(3) Außerordentliche Mitglieder können alle Personen werden, die sich zur physischen Mitarbeit an der Vereinsarbeit oder Zahlung eines Mitgliedsbeitrags in dem von der Generalversammlung festgesetzten Umfang verpflichten. („Backstage Chor")</p>
                    <p>(4) Zu Ehrenmitgliedern können durch Beschluss der Generalversammlung auf Antrag des Vorstands Personen ernannt werden, die sich um den Verein besonders verdient gemacht haben. Ehrenmitglieder sind von der Verpflichtung zur Mitarbeit und zur
                        Leistung von Zahlungen befreit.</p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                    <h3>§ 5: Erwerb der Mitgliedschaft</h3>
                    <p>(1) Mitglieder des Vereins können alle physischen Personen werden.</p>
                    <p>(2) Über die Aufnahme von ordentlichen und außerordentlichen Mitgliedern entscheidet der Vorstand. Die Aufnahme kann ohne Angabe von Gründen verweigert werden. Für die Aufnahme außerordentlicher Mitglieder kann die Generalversammlung auf Antrag
                        des Vorstands eine Aufnahmegebühr festsetzen.</p>
                    <p>(3) Bis zur Entstehung des Vereins erfolgt die vorläufige Aufnahme von ordentlichen und außerordentlichen Mitgliedern durch die Vereinsgründer, im Fall eines bereits bestellten Vorstands durch diesen. Diese Mitgliedschaft wird erst mit Entstehung
                        des Vereins wirksam. Wird ein Vorstand erst nach Entstehung des Vereins bestellt, erfolgt auch die (definitive) Aufnahme ordentlicher und außerordentlicher Mitglieder bis dahin durch die Gründer des Vereins.</p>
                    <p>(4) Die Ernennung zum Ehrenmitglied erfolgt auf Antrag des Vorstands durch die Generalversammlung.</p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                    <h3>§ 6: Beendigung der Mitgliedschaft</h3>
                    <p>(1) Die Mitgliedschaft erlischt durch Tod, durch freiwilligen Austritt und durch Ausschluss.</p>
                    <p>(2) Der Austritt kann jederzeit erfolgen. Er muss dem Vorstand mindestens eine Woche vorher schriftlich mitgeteilt werden.</p>
                    <p>(3) Der Vorstand kann ein Mitglied ausschließen, wenn dieses trotz zweimaliger schriftlicher Mahnung unter Setzung einer angemessenen Nachfrist länger als sechs Monate mit der Zahlung der Mitgliedsbeiträge oder Erbringung der zugesagten Leistungen
                        im Rückstand ist. Die Verpflichtung zur Zahlung der fällig gewordenen Mitgliedsbeiträge bleibt hievon unberührt.</p>
                    <p>(4) Der Ausschluss eines Mitglieds aus dem Verein kann vom Vorstand auch wegen grober Verletzung anderer Mitgliedspflichten und wegen unehrenhaften Verhaltens verfügt werden.</p>
                    <p>(5) Die Aberkennung der Ehrenmitgliedschaft kann aus den im Abs. 4 genannten Gründen von der Generalversammlung über Antrag des Vorstands beschlossen werden.</p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                    <h3>§ 7: Rechte und Pflichten der Mitglieder</h3>
                    <p>(1) Die Mitglieder sind berechtigt, an allen Veranstaltungen des Vereins teilzunehmen und die Einrichtungen des Vereins zu beanspruchen. Das Stimmrecht in der Generalversammlung sowie das aktive und passive Wahlrecht steht nur den ordentlichen
                        Mitgliedern ab dem vollendeten 18. Lebensjahr und den Ehrenmitgliedern zu.</p>
                    <p>(2) Jedes Mitglied ist berechtigt, vom Vorstand die Ausfolgung der Statuten zu verlangen.</p>
                    <p>(3) Mindestens ein Zehntel der Mitglieder kann vom Vorstand die Einberufung einer Generalversammlung verlangen.</p>
                    <p>(4) Die Mitglieder sind in jeder ordentlichen Generalversammlung vom Vorstand über die Tätigkeit und finanzielle Gebarung des Vereins zu informieren. Wenn mindestens ein Zehntel der Mitglieder dies unter Angabe von Gründen verlangt, hat der
                        Vorstand den betreffenden Mitgliedern eine solche Information auch sonst binnen vier Wochen zu geben.</p>
                    <p>(5) Die Mitglieder sind vom Vorstand über den geprüften Rechnungsabschluss (Rechnungslegung) zu informieren. Geschieht dies in der Generalversammlung, sind die Rechnungsprüfer einzubinden.</p>
                    <p>(6) Die Mitglieder sind verpflichtet, die Interessen des Vereins nach Kräften zu fördern und alles zu unterlassen, wodurch das Ansehen und der Zweck des Vereins Abbruch erleiden könnte. Sie haben die Vereinsstatuten und die Beschlüsse der
                        Vereinsorgane zu beachten. Die außerordentlichen Mitglieder sind zur pünktlichen Zahlung der festgesetzten Beitrittsgebühr, die ordentlichen und außerordentlichen Mitglieder sind zur Erbringung der festgesetzten Mindestleistungen oder der
                        festgesetzten Mitgliedsbeiträge in der von der Generalversammlung beschlossenen Höhe verpflichtet. Die ordentlichen und außerordentlichen Mitglieder sind zur Erfüllung ihrer unentgeltlichen Verpflichtung zur physischen Mitarbeit in dem von ihnen
                        gewählten Aufgabengebiet verpflichtet.</p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                    <h3>§ 8: Vereinsorgane</h3>
                    <p>Organe des Vereins sind die Generalversammlung (§§ 9 und 10), der Vorstand (§§ 11 bis 13), die Rechnungsprüfer (§ 14) und die Schlichtungsstelle (§ 15).</p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                    <h3>§ 9: Generalversammlung</h3>
                    <p>(1) Die Generalversammlung ist die „Mitgliederversammlung" im Sinne des Vereinsgesetzes 2002. Eine ordentliche Generalversammlung findet alle drei Jahre statt.</p>
                    <p>(2) Eine außerordentliche Generalversammlung findet auf
                        <ol>
                        <li>Beschluss des Vorstands oder der ordentlichen Generalversammlung,</li>
                        <li>schriftlichen Antrag von mindestens einem Zehntel der Mitglieder,</li>
                        <li>Verlangen der Rechnungsprüfer (§ 21 Abs. 5 erster Satz VereinsG),</li>
                        <li>Beschluss der/eines Rechnungsprüfer/s (§ 21 Abs. 5 zweiter Satz VereinsG, § 11 Abs. 2 dritter Satz dieser Statuten),</li>
                        <li>Beschluss eines gerichtlich bestellten Kurators (§ 11 Abs. 2 letzter Satz dieser Statuten)</li>
                        </ol>
                        binnen vier Wochen statt.
                    </p>
                    <p>(3) Sowohl zu den ordentlichen wie auch zu den außerordentlichen Generalversammlungen sind alle Mitglieder mindestens zwei Wochen vor dem Termin schriftlich, mittels Telefax oder per E-Mail (an die vom Mitglied dem Verein bekanntgegebene
                        Fax-Nummer oder E-Mail-Adresse) einzuladen. Die Anberaumung der Generalversammlung hat unter Angabe der Tagesordnung zu erfolgen. Die Einberufung erfolgt durch den Vorstand (Abs. 1 und Abs. 2 lit. a – c), durch die/einen Rechnungsprüfer (Abs. 2 lit.
                        d) oder durch einen gerichtlich bestellten Kurator (Abs. 2 lit. e).</p>
                    <p>(4) Anträge zur Generalversammlung sind mindestens drei Tage vor dem Termin der Generalversammlung beim Vorstand schriftlich, mittels Telefax oder per E-Mail einzureichen.</p>
                    <p>(5) Gültige Beschlüsse – ausgenommen solche über einen Antrag auf Einberufung einer außerordentlichen Generalversammlung – können nur zur Tagesordnung gefasst werden.</p>
                    <p>(6) Bei der Generalversammlung sind alle Mitglieder teilnahmeberechtigt. Stimmberechtigt sind nur die ordentlichen Mitglieder ab dem vollendeten 18. Lebensjahr und die Ehrenmitglieder. Jedes ordentliche Mitglied ab dem vollendeten 18. Lebensjahr
                        und jedes Ehrenmitglied hat eine Stimme. Die Übertragung des Stimmrechts auf ein anderes ordentliches Mitglied ab dem vollendeten 18. Lebensjahr oder Ehrenmitglied im Wege einer schriftlichen Bevollmächtigung ist zulässig.</p>
                    <p>(7) Die Generalversammlung ist nach ordnungsgemäßer Ladung ohne Rücksicht auf die Anzahl der Erschienenen beschlussfähig.</p>
                    <p>(8) Die Wahlen und die Beschlussfassungen in der Generalversammlung erfolgen, soweit in den Statuten nichts anderes bestimmt wird, mit einfacher Mehrheit der abgegebenen gültigen Stimmen. Beschlüsse, mit denen das Statut des Vereins geändert oder
                        der Verein aufgelöst werden soll, bedürfen jedoch einer qualifizierten Mehrheit von zwei Dritteln der abgegebenen gültigen Stimmen.</p>
                    <p>(9) Den Vorsitz in der Generalversammlung führt der/die Obmann/Obfrau, in dessen/deren Verhinderung sein/e/ihr/e Stellvertreter/in. Wenn auch diese/r verhindert ist, so führt das an Jahren älteste anwesende Vorstandsmitglied den Vorsitz.</p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                    <h3>§ 10: Aufgaben der Generalversammlung</h3>
                    <p>Der Generalversammlung sind folgende Aufgaben vorbehalten:
                        <ol>
                        <li>Beschlussfassung über den Voranschlag;</li>
                        <li>Entgegennahme und Genehmigung des Rechenschaftsberichts und des Rechnungsabschlusses unter Einbindung der Rechnungsprüfer;</li>
                        <li>Wahl und Enthebung der Mitglieder des Vorstands und der Rechnungsprüfer;</li>
                        <li>Genehmigung von Rechtsgeschäften zwischen Rechnungsprüfern und Verein;</li>
                        <li>Entlastung des Vorstands;</li>
                        <li>Festsetzung der Höhe der Beitrittsgebühr, der Mindestleistungsverpflichtung und der Mitgliedsbeiträge für ordentliche und für außerordentliche Mitglieder;</li>
                        <li>Verleihung und Aberkennung der Ehrenmitgliedschaft;</li>
                        <li>Beschlussfassung über Statutenänderungen und die freiwillige Auflösung des Vereins;</li>
                        <li>Beratung und Beschlussfassung über sonstige auf der Tagesordnung stehende Fragen.</li>
                        </ol>
                    </p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                    <h3>§ 11: Vorstand</h3>
                    <p>(1) Der Vorstand besteht aus zumindest drei Mitgliedern, und zwar aus dem/der Obmann/Obfrau, dem/der Schriftführer/in sowie dem/der Kassier/in. Weitere Vorstandsmitglieder bis zu einer Gesamtzahl von acht können gewählt werden.</p>
                    <p>(2) Der Vorstand wird von der Generalversammlung gewählt. Der Vorstand hat bei Ausscheiden eines gewählten Mitglieds das Recht, an seine Stelle ein anderes wählbares Mitglied zu kooptieren, wozu die nachträgliche Genehmigung in der nächstfolgenden
                        Generalversammlung einzuholen ist. Fällt der Vorstand ohne Selbstergänzung durch Kooptierung überhaupt oder auf unvorhersehbar lange Zeit aus, so ist jeder Rechnungsprüfer verpflichtet, unverzüglich eine außerordentliche Generalversammlung zum Zweck
                        der Neuwahl eines Vorstands einzuberufen. Sollten auch die Rechnungsprüfer handlungsunfähig sein, hat jedes ordentliche Mitglied, das die Notsituation erkennt, unverzüglich die Bestellung eines Kurators beim zuständigen Gericht zu beantragen, der
                        umgehend eine außerordentliche Generalversammlung einzuberufen hat.</p>
                    <p>(3) Die Funktionsperiode des Vorstands beträgt<sup id="fnref1">
                        <a href="#fn1" rel="footnote">1</a>
                        </sup>
                        drei Jahre; eine Wiederwahl ist möglich. Jede Funktion im Vorstand ist persönlich auszuüben.</p>
                    <p>(4) Der Vorstand wird vom Obmann/von der Obfrau, bei Verhinderung von seinem/seiner/ihrem/ihrer Stellvertreter/in, schriftlich oder mündlich einberufen. Ist auch diese/r auf unvorhersehbar lange Zeit verhindert, darf jedes sonstige
                        Vorstandsmitglied den Vorstand einberufen.</p>
                    <p>(5) Der Vorstand ist beschlussfähig, wenn alle seine Mitglieder eingeladen wurden und zumindest zwei von ihnen anwesend ist.</p>
                    <p>(6) Der Vorstand fasst seine Beschlüsse mit Einstimmigkeit.</p>
                    <p>(7) Den Vorsitz führt der/die Obmann/Obfrau, bei Verhinderung obliegt der Vorsitz dem an Jahren ältesten anwesenden Vorstandsmitglied oder jenem Vorstandsmitglied, das die übrigen Vorstandsmitglieder mehrheitlich dazu bestimmen.</p>
                    <p>(8) Außer durch den Tod und Ablauf der Funktionsperiode (Abs. 3) erlischt die Funktion eines Vorstandsmitglieds durch Enthebung (Abs. 9) und Rücktritt (Abs. 10).</p>
                    <p>(9) Die Generalversammlung kann jederzeit den gesamten Vorstand oder einzelne seiner Mitglieder entheben. Die Enthebung tritt mit Bestellung des neuen Vorstands bzw Vorstandsmitglieds in Kraft.</p>
                    <p>(10) Die Vorstandsmitglieder können jederzeit schriftlich ihren Rücktritt erklären. Die Rücktrittserklärung ist an den Vorstand, im Falle des Rücktritts des gesamten Vorstands an die Generalversammlung zu richten. Der Rücktritt wird erst mit Wahl
                        bzw Kooptierung (Abs. 2) eines Nachfolgers wirksam.</p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                    <h3>§ 12: Aufgaben des Vorstands</h3>
                    <p>Dem Vorstand obliegt die Leitung des Vereins. Er ist das „Leitungsorgan" im Sinne des Vereinsgesetzes 2002. Ihm kommen alle Aufgaben zu, die nicht durch die Statuten einem anderen Vereinsorgan zugewiesen sind. In seinen Wirkungsbereich fallen
                        insbesondere folgende Angelegenheiten:
                        <ol>
                        <li>Einrichtung eines den Anforderungen des Vereins entsprechenden Rechnungswesens mit laufender Aufzeichnung der Einnahmen/Ausgaben und Führung eines Vermögensverzeichnisses;</li>
                        <li>Erstellung des Jahresvoranschlags, des Rechenschaftsberichts und des Rechnungsabschlusses;</li>
                        <li>Vorbereitung und Einberufung der Generalversammlung in den Fällen des § 9 Abs. 1 und Abs. 2 lit. a – c dieser Statuten;</li>
                        <li>Information der Vereinsmitglieder über die Vereinstätigkeit, die Vereinsgebarung und den geprüften Rechnungsabschluss;</li>
                        <li>Verwaltung des Vereinsvermögens;</li>
                        <li>Aufnahme und Ausschluss von ordentlichen und außerordentlichen Vereinsmitgliedern;</li>
                        <li>Aufnahme und Kündigung von Angestellten des Vereins.</li>
                        <li>Anzeige der Änderungen der Statuten, der organschaftlichen Vertreter und der Vereinsanschrift an die Vereinsbehörde.</li>
                        <li>Mitteilung der Vereinsbehörde über die freiwillige Auflösung des Vereins innerhalb von 4 Wochen.</li>
                        </ol>
                    </p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                    <h3>§ 13: Besondere Obliegenheiten einzelner Vorstandsmitglieder</h3>
                    <p>(1) Der/die Obmann/Obfrau führt die laufenden Geschäfte des Vereins. Der/die Schriftführer/in unterstützt den/die Obmann/Obfrau bei der Führung der Vereinsgeschäfte.</p>
                    <p>(2) Der/die Obmann/Obfrau vertritt den Verein nach außen. Schriftliche Ausfertigungen des Vereins bedürfen zu ihrer Gültigkeit der Unterschriften des/der Obmanns/Obfrau und des Schriftführers/der Schriftführerin, in Geldangelegenheiten
                        (vermögenswerte Dispositionen) des/der Obmanns/Obfrau und des Kassiers/der Kassierin. Rechtsgeschäfte zwischen Vorstandsmitgliedern und Verein bedürfen der Zustimmung eines anderen Vorstandsmitglieds.</p>
                    <p>(3) Rechtsgeschäftliche Bevollmächtigungen, den Verein nach außen zu vertreten bzw. für ihn zu zeichnen, können ausschließlich von den in Abs. 2 genannten Vorstandsmitgliedern erteilt werden.</p>
                    <p>(4) Bei Gefahr im Verzug ist der/die Obmann/Obfrau berechtigt, auch in Angelegenheiten, die in den Wirkungsbereich der Generalversammlung oder des Vorstands fallen, unter eigener Verantwortung selbständig Anordnungen zu treffen; im Innenverhältnis
                        bedürfen diese jedoch der nachträglichen Genehmigung durch das zuständige Vereinsorgan.</p>
                    <p>(5) Der/die Obmann/Obfrau führt den Vorsitz in der Generalversammlung und im Vorstand.</p>
                    <p>(6) Der/die Schriftführer/in führt die Protokolle der Generalversammlung und des Vorstands.</p>
                    <p>(7) Der/die Kassier/in ist für die ordnungsgemäße Geldgebarung des Vereins verantwortlich.</p>
                    <p>(8) Im Fall der Verhinderung treten an die Stelle des/der Obmanns/Obfrau, des Schriftführers/der Schriftführerin oder des Kassiers/der Kassierin gegebenenfalls deren Stellvertreter/innen.</p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                    <h3>§ 14: Rechnungsprüfer</h3>
                    <p>(1) Zwei Rechnungsprüfer werden von der Generalversammlung auf die Dauer von drei Jahren gewählt. Eine Wiederwahl ist möglich. Die Rechnungsprüfer dürfen keinem Organ – mit Ausnahme der Generalversammlung – angehören, dessen Tätigkeit Gegenstand
                        der Prüfung ist.</p>
                    <p>(2) Den Rechnungsprüfern obliegt die laufende Geschäftskontrolle sowie die Prüfung der Finanzgebarung des Vereins im Hinblick auf die Ordnungsmäßigkeit der Rechnungslegung und die statutengemäße Verwendung der Mittel. Der Vorstand hat den
                        Rechnungsprüfern die erforderlichen Unterlagen vorzulegen und die erforderlichen Auskünfte zu erteilen. Die Rechnungsprüfer haben dem Vorstand über das Ergebnis der Prüfung zu berichten. Die Prüfung hat innerhalb von 4 Monaten ab Erstellung der
                        Einnahmen- und Ausgabenrechnung zu erfolgen.</p>
                    <p>(3) Rechtsgeschäfte zwischen Rechnungsprüfern und Verein bedürfen der Genehmigung durch die Generalversammlung. Im Übrigen gelten für die Rechnungsprüfer die Bestimmungen des § 11 Abs. 8 bis 10 sinngemäß.</p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                    <h3>§ 15: Schlichtungsstelle</h3>
                    <p>(1) Zur Schlichtung von allen aus dem Vereinsverhältnis entstehenden Streitigkeiten ist die vereinsinterne Schlichtungsstelle berufen. Sie ist eine „Schlichtungseinrichtung" im Sinne des Vereinsgesetzes 2002 und kein Schiedsgericht nach den §§ 577
                        ff ZPO.</p>
                    <p>(2) Die Schlichtungsstelle setzt sich aus drei ordentlichen Vereinsmitgliedern zusammen. Es wird derart gebildet, dass ein Streitteil dem Vorstand ein Mitglied als Schlichter schriftlich namhaft macht. Über Aufforderung durch den Vorstand binnen
                        sieben Tagen macht der andere Streitteil innerhalb von 14 Tagen seinerseits ein Mitglied der Schlichtungsstelle namhaft. Nach Verständigung durch den Vorstand innerhalb von sieben Tagen wählen die namhaft gemachten Schlichter binnen weiterer 14 Tage
                        ein drittes ordentliches Mitglied zum/zur Vorsitzenden der Schlichtungsstelle. Bei Stimmengleichheit entscheidet unter den Vorgeschlagenen das Los. Die Mitglieder der Schlichtungsstelle dürfen keinem Organ – mit Ausnahme der Generalversammlung –
                        angehören, dessen Tätigkeit Gegenstand der Streitigkeit ist.</p>
                    <p>(3) Die Schlichtungsstelle fällt ihre Entscheidung nach Gewährung beiderseitigen Gehörs bei Anwesenheit aller seiner Mitglieder mit einfacher Stimmenmehrheit. Es entscheidet nach bestem Wissen und Gewissen.</p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                    <h3>§ 16: Freiwillige Auflösung des Vereins</h3>
                    <p>(1) Die freiwillige Auflösung des Vereins kann nur in einer Generalversammlung und nur mit Zweidrittelmehrheit der abgegebenen gültigen Stimmen beschlossen werden.</p>
                    <p>(2) Diese Generalversammlung hat auch – sofern Vereinsvermögen vorhanden ist – über die Abwicklung zu beschließen. Insbesondere hat sie einen Abwickler zu berufen und Beschluss darüber zu fassen, wem dieser das nach Abdeckung der Passiven
                        verbleibende Vereinsvermögen zu übertragen hat. Dieses Vermögen soll, soweit dies möglich und erlaubt ist, einer Organisation zufallen, die gleiche oder ähnliche Zwecke wie dieser Verein verfolgt, sonst Zwecken der Sozialhilfe.</p>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                    <p>Wien, 22. April 2016</p>
                    </div>
                </div>
                <div className="row mb-4 footnotes">
                    <div className="col-12">
                    <p id="fn1">
                        <sup>1</sup>&nbsp;zB zwei oder vier Jahre (abgestimmt auf den Abstand zwischen ordentlichen Generalversammlungen nach § 9 Abs 1).&nbsp;<a href="#fnref1">&#8617;</a>
                    </p>
                    </div>
                </div>
                </div>
            </section>
        <Footer />
        </>
}
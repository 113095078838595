import React, { useCallback, useState } from 'react';

type NavContextType = {
	active: string,
	setActive: (id: string) => void
}

export const NavContext = React.createContext<NavContextType | undefined>(undefined)

export const NavProvider = (props: React.PropsWithChildren<{}>) => {
	const [activeNavLinkId, setActiveNavLinkId] = useState('');

	const setActive = useCallback((value: string) => {
		// console.log("SetActive to " + value)
		setActiveNavLinkId(value)
	}, [])

	const providerValue = {
		active: activeNavLinkId,
		setActive: setActive,
	}

	return (
		<NavContext.Provider value={providerValue}>{props.children}</NavContext.Provider>
	);
};

import { RefObject, useEffect, useState } from "react"
import { viewportHeight } from "../helper/viewportPixels";

export default function useOnScreen<T extends HTMLElement = HTMLDivElement>(ref: RefObject<T>) {
	const [isOnScreen, setOnScreen] = useState(false);

	// Sets the threshold to be 51% of the viewport height or calculates the relation of
	// of 51% of the objects height if it is > than the view port.
	const threshold = viewportHeight(100) / (ref.current?.clientHeight || viewportHeight(100)) * 0.51 
	
	const observer = new IntersectionObserver(
		([entry]) => setOnScreen(entry.isIntersecting),
		{
			root: null,
			rootMargin: "10px",
			threshold: threshold
		}
	)

	useEffect(() => {
		ref.current && observer.observe(ref.current)
		return () => observer.disconnect()
	})

	return isOnScreen;
};
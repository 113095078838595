import { Accordion } from "react-bootstrap";


export default function FAQs() : JSX.Element {

    const faqs: {question: string | JSX.Element, answer: string | JSX.Element }[] = [
        {
            question: "Was ist UNISONO?",
            answer: "UNISONO ist der Verein für ehemalige Wiener Sängerknaben und Absolvent:innen des ORGs der Wiener Sängerknaben."
        },
        {
            question: "Was macht UNISONO?",
            answer: <><p>Bei UNISONO möchten wir mit Freunden und Bekannten aus unserer Schulzeit in Kontakt bleiben.</p><p>Wir veranstalten unterschiedliche Events, zu denen Du als Mitglied eingeladen bist.</p><p className="mb-0">Außerdem hast Du durch eine Mitgliedschaft Zugang zu <q>UNISONO Club</q>, unserer Online-Platform für Mitglieder. Finde alte Freunde oder neue Kontakte, stöbere Dich durch unseren Veranstaltungs-Katalog, und pflege Deine Mitgliedschaft ganz bequem online.</p></>
        },
        {
            question: <span>Was ist der <q>UNISONO Club</q>?</span>,
            answer: <p className="mb-0">Als <q>UNISONO Club</q> bezeichnen wir unsere Online-Platform, die nur für Mitglieder erreichbar ist. Dort schließt Du auch eine neue Mitgliedschaft ab.</p>
        },
        {
            question: <span>Ich bin bereits Mitglied. Wie kann ich <q>UNISONO Club</q> nutzen?</span>,
            answer: <><p>Wenn Du eine Mitgliedschaft vor 2022 abgeschlossen hast, bist Du zwar Mitglied, hast aber noch keinen Account, um auf <q>UNISONO Club</q> zugreifen zu können.</p><p>Dafür musst Du zuerst ein Passwort setzen. Gehe hierfür auf <a href="https://club.unisono-alumni.at/reset" target="_blank" rel="noreferrer">club.unisono-alumni.at/reset</a> und folge den Schritten, um Dein Passwort zurückzusetzen.</p><p className="mb-0">Verwende die E-Mail Adresse, die Du auch bei Deiner Anmeldung als Mitglied angegeben hast.</p></>
        },
        {
            question: "Wie werde ich Mitglied?",
            answer: <><p>Um eine neue Mitgliedschaft abzuschließen, gehe auf <a href="https://club.unisono-alumni.at/sign-up">club.unisono-alumni.at</a> und erstelle Dir einen Account mit Deiner E-Mail Adresse und einem Passwort. Fülle bitte auch die notwendingen Informationen in Deinen Einstellungen aus.</p><p className="mb-0">Wenn alles passt, verifizieren wir Deinen Account und schon bist Du Mitglied.</p></>
        },
        {
            question: <>Meine E-Mail Adresse ist nicht mehr aktuell. Ich kann mein Passwort nicht zurücksetzen.</>,
            answer: <>Bitte melde Dich bei <a href="mailto:technik@unisono-alumni.at">technik@unisono-alumni.at</a>. Wir versuchen eine Lösung zu finden, bei der wir Deine Identität bestätigen können.</>
        },
        {
            question: "Wieviel kostet eine Mitgliedschaft?",
            answer: "Eine Mitgliedschaft bei UNISONO kostet 30 € im Jahr. Studierende, Präsenz- und Zivildiener können den ermäßigten Tarif von 15 € im Jahr in Anspruch nehmen. Personen unter 18 Jahren sind vom Mitgliedsbeitrag befreit."
        }
    ]

    return  <Accordion>{faqs.map( (item, i) => {
                return <Accordion.Item key={i} eventKey={i.toString()}>
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                </Accordion.Item>
            })}</Accordion>
}
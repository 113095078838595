import React, { useContext, useCallback, useState } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/logo/UNISONO_TextLogo_24_xs.png"
import { NavContext } from "../../context/NavProvider";
import { SectionProps } from "../../controllers/OnePager";

export type NavLinkProps = { id: string, display: string, path?: string, toRef?: React.RefObject<HTMLDivElement>};
export type NavSpy = {component: (props: SectionProps) => JSX.Element}

const NavLink = (props: NavLinkProps & {activeNavLinkId: string, setActiveNavLinkId: (id: string) => void, setExpanded: (expanded: boolean) => void}) => {

	const handleClick = () => {
        props.setActiveNavLinkId(props.id)
        if (props.toRef) {
            props.toRef.current?.scrollIntoView({behavior: 'smooth'})
        } else {
            document.getElementById(props.id)?.scrollIntoView({behavior: 'smooth'})
        }
        props.setExpanded(false)
	};
	
	return (
        <li className="nav-item" key={'nav-' + (props.path || props.id)}>
            <Link to={"/" + (props.path || props.id)}
                id={'nav-' + (props.path || props.id)} 
                className={'btn btn-link d-inline-block nav-link ' + (props.activeNavLinkId === props.id ? 'active ' : '')}
                onClick={handleClick}
                style={{fontSize: "90%"}}
            >
                {props.display}
            </Link>
        </li>
	);
};

export function NavLinks(props: {navLinks: NavLinkProps[], setExpanded: (expanded: boolean) => void}): JSX.Element {

    const context = useContext(NavContext)

    const activeNavLinkId = context ? context.active : 'index'
    
    const setActiveNavLinkId = useCallback((value: string) => {
        context?.setActive(value)
    }, [context])

    return  <>
            {props.navLinks.map((navLink) =>
                <NavLink
                    key={navLink.id}
                    id={navLink.id} 
                    display={navLink.display} 
                    activeNavLinkId={activeNavLinkId}
                    setActiveNavLinkId={setActiveNavLinkId}
                    toRef={navLink.toRef}
                    setExpanded={props.setExpanded}
                />
            )}
            </>
}

export default function NavBar(props: {navLinks: NavLinkProps[], className?: string, relative?: boolean}): JSX.Element {
    const [expanded, setExpanded] = useState(false)
    return  <Navbar expand="xl" fixed={props.relative ? undefined : "top"} className={props.className} expanded={expanded} onToggle={()=>setExpanded(!expanded)}>
                <Container style={{
                    background: "rgba(255,255,255,0.3)",
                    WebkitBackdropFilter: "blur(10px) saturate(150%)",
                    backdropFilter: "blur(10px) saturate(150%)",
                    border: ".5px solid rgba(0,0,0, 0.2)",
                    padding: "1rem 1.5rem",
                    borderRadius: ".5rem .75rem",
                    boxShadow: "0 0 10px rgba(0,0,0,0.15)"
                }}>
                    <LinkContainer to="/" style={{marginRight: "1.5rem"}}><Navbar.Brand><img src={logo} alt="UNISONO Logo" height={20} className="d-inline-block" style={{position: "relative", top: "-3px"}} /></Navbar.Brand></LinkContainer>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse>
                        <Nav className="me-auto">
                            <NavLinks navLinks={props.navLinks} setExpanded={setExpanded} />
                        </Nav>
                        <ul className="navbar-nav">
                            <li className="nav-item"><a href="https://club.unisono-alumni.at/" className="btn btn-nav btn-sm" style={{fontSize: "90%"}}>UNISONO Club</a></li>
                        </ul>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
}
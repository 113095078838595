import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ScrollToTop from "../../components/ScrollToTop";
import { NavContext } from "../../context/NavProvider";

export default function Imprint(): JSX.Element {

    const navbar = useContext(NavContext)
    navbar?.setActive('')
    
    return  <>
                <ScrollToTop />
                <Header title="Impressum" relative />
                <section style={{padding: "3rem 0"}}>
                    <Container>
                        <Row>
                            <Col>
                                <h3>Verein</h3>
                                <p className="lh-1">
                                    unisono<br />
                                    Verein der Absolventen und Absolventinnen und der<br />
                                    freiwilligen Mitarbeiter und Mitarbeiterinnen der<br />
                                    Institution der Wiener Sängerknaben
                                </p>
                                <h3>Sitz</h3>
                                <address className="lh-1">
                                Obere Augartenstraße 1c<br />
                                1020 Wien<br />
                                Österreich
                                </address>
                                <p>ZVR: 094 500 324</p>
                            </Col>
                            <Col>
                                <h3>Kontakt</h3>
                                <ul className="list-unstyled">
                                    <li>Allgemeine Anfragen: <a href="mailto:info@unisono-alumni.at">info@unisono-alumni.at</a></li>
                                    <li>Website &amp; Technik: <a href="mailto:technik@unisono-alumni.at">technik@unisono-alumni.at</a></li>
                                </ul>

                                <h3>Der Vorstand</h3>
                                <ul className="list-unstyled">
                                    <li>Gerald Wehrberger, Obmann</li>
                                    <li>Elke Hesse, Stellvertretende Obfrau</li>
                                    <li>Jutta Camen, Schriftführerin</li>
                                    <li>Simon Frycer, Stellvertretender Schriftführer</li>
                                    <li>Nathan Gundacker, Kassier</li>
                                    <li>Imma Mautner-Markhof, Backstage Chor</li>
                                    <li>Fabian Eisenhut, Informationstechnik</li>
                                </ul>

                                <p>Für den Inhalt verantwortlich: Vorsitz des Vorstands</p>
                            </Col>
                        </Row>
                    </Container>
                    </section>
                <Footer />
            </>
}
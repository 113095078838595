import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ScrollToTop from "../../components/ScrollToTop";
import { NavContext } from "../../context/NavProvider";

export default function Privacy(): JSX.Element {

    const navbar = useContext(NavContext)
    navbar?.setActive('')
    
return <>
    <ScrollToTop />
    <Header title="Datenschutz" relative />
    <Container className="py-4">
        <Row>
            <Col className="text-center">
                {/* <h1>Datenschutzerklärung</h1> */}
                {/* Verein der Absolventen und Absolventinnen und der freiwilligen Mitarbeiter und Mitarbeiterinnen
                    der Institution der Wiener Sängerknaben<br />ZVR-Zahl: 094 500 324<br /> */}
                <p>Letzte Aktualisierung: 09.09.2019</p>
                <p>
                    In dieser Datenschutzerklärung wird beschrieben, wie wir Ihre Daten im Zusammenhang mit Ihrer
                    Mitgliedschaft im Verein UNISONO und der Anmeldung als Mitglied erheben, nutzen, verarbeiten und
                    weitergeben.
                </p>
            </Col>
        </Row>
    </Container>
    <Container style={{columnCount: 2, columnGap: "2rem"}} className="py-4">
        {/* <Row className="my-4">
            <div className="col">
                <div className="alert alert-primary" role="alert">
                    <p className="mb-0">Dieser Text ist nur in Deutsch verfügbar.</p>
                </div>
            </div>
        </div> */}
        <Row className="my-4">
            <Col>
                <h2>1 Von uns erhobene Daten</h2>
                <p>Bei der Anmeldung als UNISONO Mitglied erheben wir die folgenden persönlichen Daten von Ihnen.
                    Diese Daten benötigen wir, um unseren Vereinsaufgaben und unseren gesetzlichen Verpflichtungen
                    als Verein nachzukommen. Ohne diese Daten können wir Sie möglicherweise nicht als Mitglied
                    aufnehmen.</p>
                <h3>1.1 Persönliche Daten</h3>
                <ul>
                    <li>Anrede</li>
                    <li>Vor- und Nachnamen</li>
                    <li>Geburtsdatum</li>
                    <li>Telefonnummer</li>
                    <li>E-Mail Adresse</li>
                    <li>Anschrift</li>
                </ul>
                <h3>1.2 Institutsspezifische Daten</h3>
                <ul>
                    <li>Zeitraum, in dem Sie die Institution der Wiener Sängerknaben (RG und/oder ORG der Wiener
                        Sängerknaben) besucht haben</li>
                    <li>In welchem Chor/ welchen Chören Sie in diesem Zeitraum Mitglied waren (sowohl RG als auch
                        ORG der Wiener Sängerknaben)</li>
                </ul>
                <h3>1.3 Daten zur Verifizierung Ihrer Identität</h3>
                <p>Im Interesse der Schaffung und Erhaltung einer sicheren Umgebung können wir Daten zur
                    Verifizierung Ihrer Identität (wie etwa das Foto eines amtlichen Ausweises, eines Reisepasses,
                    eines Personalausweises oder eines Führerscheins – sofern gesetzlich zugelassen –, Zeugnisse
                    oder Urkunden von den Schulen der Wiener Sängerknaben) oder andere Authentifizierungsdaten
                    erheben.</p>
                <ul>
                    <li>Kopie eines amtlichen LIchtbildausweises (Reisepass, Personalausweis, Führerschein – sofern
                        gesetzlich zugelassen)</li>
                    <li>Kopie einer Bestätigung der Zugehörigkeit zu der Institution der Wiener Sängerknaben
                        (Darunter fällt: Urkunde; Schulzeugnis)</li>
                </ul>
                <h3>1.4 Bürgschaft</h3>
                <p>Ein bereits aufgenommenes Mitglied kann für die Zugehörigkeit zu der Institution der Wiener
                    Sängerknaben eines/r InteressentIn an einer Mitgliedschaft bürgen. Hierfür wird von UNISONO ein
                    Dokument über die Bürgschaft an das bürgende Mitglied ausgehändigt, welches dieses zu
                    unterzeichnen hat. Die Person, für die das Mitglied bürgt, hat dann dieses Dokument anstelle der
                    Bestätigung der Zugehörigkeit hochzuladen. Folgende Daten werden in diesem Fall gesammelt
                    beziehungsweise gespeichert:</p>
                <ul>
                    <li>Name und Mitgliedsdaten des Bürgenden</li>
                    <li>Name und Mitgliedsdaten des/r InteressentIn</li>
                    <li>Dass eine Bürgschaft eingegangen wurde</li>
                </ul>
                <p>Für den Fall, dass UNISONO eine Bürgschaft für ein/e Interessentin eingeht, werden nur Name und
                    Mitgliedsdaten des/r InteressentIn gespeichert und, dass jene Bürgschaft eingegangen wurde.</p>
            </Col>
            </Row>
        <Row className="my-4">
            <Col>
                <h2>2 Wie wir die von uns erhobenen Daten nutzen</h2>
                <p>Wir nutzen, speichern und verarbeiten Informationen, einschließlich personenbezogener Daten, über
                    Sie, um unsere Vereinsstatuten zu erfüllen, sowie um eine vertrauenswürdige und sichere Umgebung
                    zu schaffen und zu erhalten und um unseren gesetzlichen Verpflichtungen nachzukommen.</p>
                <h3>2.1 Erfüllung der Vereinsstatuten</h3>
                <p>Wir verwenden die, von Ihnen bereitgestellten Daten, um Ihnen z.B. Informationen über das
                    Vereinsleben oder von UNISONO veranstalteten Events zukommen zu lassen oder mit Ihnen in Kontakt
                    zu treten, um Ihnen Erinnerungen im Bezug auf Mitgliedsbeiträge, oder Events zu senden. Nähere
                    Informationen entnehmen Sie bitte unseren Vereinsstatuten.</p>
                <h3>2.2 Schaffung und Erhaltung einer vertrauenswürdigen und sicheren Umgebung</h3>
                <p>
                    <ul>
                        <li>Zur Ermittlung und Prävention von Betrug, Spam, Missbrauch, sicherheitsrelevanten
                            Vorfällen und sonstigen schädlichen Aktivitäten.</li>
                        <li>Zur Verifizierung bzw. Authentifizierung der von Ihnen übermittelten Informationen oder
                            Identitätsnachweise (etwa Vergleich Ihres Ausweisfotos mit einem anderen von Ihnen
                            übermittelten Foto).</li>
                        <li>Zur Einhaltung unserer gesetzlichen Verpflichtungen.</li>
                        <li>Zur Durchsetzung der Vereinsstatuten und anderer Richtlinien.</li>
                    </ul>
                    Wir verarbeiten diese Informationen, um unsere Vereinsstatuten zu erfüllen und um geltende
                    Gesetze einzuhalten.
                </p>
            </Col>
        </Row>
        <Row className="my-4">
            <Col>
                <h2>3 Weitergabe & Offenlegung</h2>
                <h3>3.1 Mit Ihrer Einwilligung</h3>
                <p>Wenn Ihre Einwilligung vorliegt, können wir Ihre Daten, einschließlich personenbezogener Daten,
                    nach Ihren Anweisungen oder gemäß den zum Zeitpunkt der Einwilligung gemachten Angaben an Andere
                    übermitteln, etwa wenn Sie zustimmen, automatisierte E-Mail Benachrichtigungen zu erhalten.
                    Diese werden mithilfe von Drittanwendungen versendet.</p>
                <h3>3.2 Einhaltung von Gesetzen, Beantworten rechtlicher Anfragen, Verhinderung von Schäden und
                    Schutz von Rechten</h3>
                <p>UNISONO kann Ihre Daten, einschließlich personenbezogener Daten, an Gerichte,
                    Strafverfolgungsbehörden und andere staatliche Stellen oder an befugte Dritte weitergeben, wenn
                    und soweit dies gesetzlich vorgeschrieben oder gestattet ist.<br />Die Offenlegung Ihrer Daten
                    kann erforderlich sein, um unseren gesetzlichen Verpflichtungen nachzukommen, um die
                    lebenswichtigen Interessen Ihrer oder einer anderen Person zu schützen oder um unser
                    berechtigtes Interesse bzw. das eines Dritten daran zu wahren, Unheil oder Straftaten zu
                    verhindern, Rechte durchzusetzen oder zu verteidigen oder Schäden zu verhindern.</p>
                <h3>3.3 MailChimp & The Rocket Science Group LLC</h3>
                <p>UNISONO nutz die Dienstleistungen des Anbieters „The Rocket Science Group, LLC d/b/a MailChimp“
                    („MailChimp“) in Bezug auf die Erstellung und Organisation von E-Mail Aussendungen. Mit Ihrer
                    Einwilligung kann UNISONO ausgewählte Daten, einschließlich personenbezogener Daten, an
                    MailChimp weiterleiten. Darunter fallen Anrede, Vor- & Nachname, Geburtsdatum, sowie E-Mail
                    Adresse. Nicht an MailChimp übertragen werden Informationen, wie Ihre Telefonnummer, Anschrift
                    und weitere Daten, die nicht oben explizit genannt sind. Die Übertragung dieser Daten kann
                    automatisiert, auch schon während des Anmeldeprozess, erfolgen. MailChimp ist durch das <a
                        href="http://privacy.truste.com/privacy-seal/The-Rocket-Science-%20Group,-LC/validation?rid=11c02a59-2371-4802-97d5-395c0bb180b4"
                        title="http://privacy.truste.com/privacy-seal/The-Rocket-Science-%20Group,-LC/validation?rid=11c02a59-2371-4802-97d5-395c0bb180b4">TrustE-Zertifikat</a>
                    und durch die Europäische Union in Bezug auf die <a
                        href="https://safeharbor.export.gov/companyinfo.aspx?id=29139"
                        title="https://safeharbor.export.gov/companyinfo.aspx?id=29139">Safe Harbor Bestimmungen</a>
                    für gute Datensicherheit ausgezeichnet.<br />Wenn Sie Information über das Vereinsleben per E-Mail
                    erhalten möchten, ist Ihre Zustimmung aus organisatorischen Gründen hierzu notwendig. Nicht
                    notwendig ist eine Zustimmung für den individuellen E-Mail Verkehr zwischen Ihnen und UNISONO
                    Mitarbeiter.</p>
            </Col>
        </Row>
        <Row className="my-4">
            <Col>
                <h2>4 Externe Partner & Integrationen</h2>
                <p>Die UNISONO Webseite können Links auf externe Webseiten oder Dienste enthalten. UNISONO ist weder
                    Inhaber dieser externen Partner noch in der Lage, sie zu kontrollieren. Diese externen Partner
                    haben eigene Regeln über die Erhebung, Nutzung und Weitergabe von Daten. Wir empfehlen Ihnen,
                    die Datenschutzerklärungen der anderen von Ihnen besuchten Webseiten einzusehen.</p>
            </Col>
        </Row>
        <Row className="my-4">
            <Col>
                <h2>5 Ihre Rechte</h2>
                <p>Sie können alle in diesem Abschnitt beschriebenen Rechte ausüben, indem Sie eine E-Mail an <a
                        href="mailto:datenschutz@unisono-alumni.at">datenschutz@unisono-alumni.at</a> senden. Bitte
                    beachten Sie, dass wir Sie möglicherweise auffordern werden, Ihre Identität zu bestätigen, bevor
                    wir weitere Maßnahmen ergreifen.</p>
                <h3>5.1 Korrektur ungenauer oder unvollständiger Angaben</h3>
                <p>Sie haben das Recht, uns aufzufordern, ungenaue oder unvollständige persönliche Angaben, die Sie
                    betreffen, zu korrigieren.</p>
                <h3>5.2 Datenzugriff und -Übertragung</h3>
                <p>Sie sind dazu berechtigt, Kopien Ihrer von uns gespeicherten persönlichen Daten anzufordern. Sie
                    sind möglicherweise auch berechtigt, Kopien von persönlichen Informationen, die Sie uns zur
                    Verfügung gestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format
                    anzufordern.</p>
                <h3>5.3 Datenerhalt und -löschung</h3>
                <p>Wir speichern Ihre personenbezogenen Daten grundsätzlich so lange, wie es für Ihre
                    Vereinsmitgliedschaft erforderlich ist, und zur Einhaltung unserer gesetzlichen Verpflichtungen.
                    Wenn Sie nicht länger möchten, dass wir Ihre Informationen verwenden, können Sie anfordern, dass
                    wir Ihre persönlichen Daten löschen und Ihre Mitgliedschaft löschen.</p>
                <div className="alert alert-warning m-4">
                    <p><b>Bitte beachten Sie Folgendes, wenn Sie die Löschung Ihrer persönlichen Daten
                            verlangen:</b></p>
                    <p className="mb-0">Wir behalten möglicherweise einige Ihrer persönlichen Daten für unsere
                        berechtigten Geschäftsinteressen, wie Betrugsaufdeckung und -prävention sowie Erhöhung der
                        Sicherheit, bei. Wenn wir beispielsweise eine UNISONO Mitgliedschaft aus Sicherheitsgründen
                        oder wegen eines Betrugsfalls sperren, behalten wir möglicherweise bestimmte Informationen
                        von dieser UNISONO Mitgliedschaft ein, um zu verhindern, dass dieses Mitglied in der Zukunft
                        erneut Mitglied wird.<br />Wir können Ihre personenbezogenen Daten in dem Umfang speichern und
                        verwenden, wie dies zur Erfüllung unserer gesetzlichen Verpflichtungen erforderlich ist. Zum
                        Beispiel kann UNISONO einige Ihrer Informationen aus steuerlichen Gründen oder für,
                        Rechtsberichterstattungs- und Buchprüfungsverpflichtungen aufbewahren.</p>
                </div>
                <h3>5.4 Widerruf der Einwilligung und Einschränkung der Verarbeitung</h3>
                <p>In den Fällen, wo Sie Ihre Einwilligung zur Verarbeitung Ihrer persönlichen Daten durch UNISONO
                    erteilt haben, können Sie Ihre Einwilligung jederzeit widerrufen, indem Sie Ihre
                    Account-Einstellungen ändern oder eine Mitteilung an UNISONO senden, in der Sie angeben, welche
                    Einwilligung Sie zurückziehen möchten. Bitte beachten Sie, dass die Zurücknahme Ihrer
                    Einwilligung keine Auswirkungen auf die Rechtmäßigkeit von Verarbeitungsvorgängen vor deren
                    Widerruf hat. Darüber hinaus kann das geltende Recht in einigen Rechtsordnungen Sie berechtigen,
                    einzuschränken wie wir Ihre persönlichen Daten verwenden, insbesondere wenn (i) Sie die
                    Richtigkeit Ihrer persönlichen Daten bestreiten; (ii) die Verarbeitung rechtswidrig ist und Sie
                    die Löschung Ihrer persönlichen Daten ablehnen; (iii) wir Ihre persönlichen Daten nicht mehr für
                    die Zwecke der Verarbeitung benötigen, aber Sie die Daten für die Begründung, Ausübung oder
                    Verteidigung von Rechtsansprüchen anfordern; oder (iv) Sie gegen die Verarbeitung Einspruch
                    erhoben haben und die Überprüfung aussteht, ob die berechtigten Interessen von UNISONO Ihre
                    eigenen überwiegen.</p>
                <h3>5.5 Beschwerden einreichen</h3>
                <p>Sie haben das Recht, bei den zuständigen Datenschutzbehörden Beschwerden über die von UNISONO
                    durchgeführte Datenverarbeitung einzureichen.</p>
            </Col>
        </Row>
        <Row className="my-4">
            <Col>
                <h2>6 Sicherheit</h2>
                <p>Zum Schutz Ihrer Daten vor unbefugtem Zugriff sowie vor Verlust, Zerstörung oder Änderung
                    ergreifen und verbessern wir fortlaufend administrative, technische und physische
                    Sicherheitsmaßnahmen. Einige der Sicherheitsmaßnahmen, die wir zum Schutz Ihrer Daten ergreifen,
                    sind Datenverschlüsselung sowie Zugriffskontrollen für Informationen.</p>
            </Col>
        </Row>
        <Row className="my-4">
            <Col>
                <h2>7 Änderungen dieser Datenschutzerklärung</h2>
                <p>UNISONO behält sich das Recht vor, diese Datenschutzerklärung jederzeit nach Maßgabe der
                    vorliegenden Bestimmung zu ändern. Nehmen wir an der Datenschutzerklärung Änderungen vor, werden
                    wir die geänderte Datenschutzerklärung auf der UNISONO Webseite veröffentlichen und das Datum
                    der „letzten Aktualisierung“ zu Beginn der Datenschutzerklärung aktualisieren. Über die
                    Änderungen setzen wir Sie außerdem mindestens dreißig Tage vor dem Zeitpunkt deren
                    In-Kraft-Tretens in Kenntnis. Wenn Sie mit der geänderten Datenschutzerklärung nicht
                    einverstanden sind, können Sie Ihre UNISONO Mitgliedschaft fristlos und kostenfrei kündigen.
                    Wenn Sie Ihre UNISONO Mitgliedschaft nicht vor dem Datum, an dem die geänderte
                    Datenschutzerklärung in Kraft tritt, kündigen, unterliegt Ihr fortgesetzter Zugriff auf die
                    UNISONO Webseite der geänderten Datenschutzrichtlinie.</p>
            </Col>
        </Row>
        <Row className="my-4">
            <Col>
                <h2>8 Ansprechpartner</h2>
                <p>Falls Sie Fragen oder Beschwerden zu dieser Datenschutzerklärung oder zu den
                    Datenverarbeitungsaktivitäten von UNISONO haben, können Sie uns wie folgt kontaktieren:</p>
                <p>
                    <address>
                        <b>UNISONO Alumni Club</b><br />
                        Obere Augartenstraße 1c<br />
                        1020, Wien<br />
                        Österreich<br />
                        <a href="mailto:datenschutz@unisono-alumni.at">datenschutz@unisono-alumni.at</a>
                    </address>
                </p>
            </Col>
        </Row>
    </Container>
    <Footer />
</>
}
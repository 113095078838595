import React, {
    useState,
    useEffect,
    createContext,
    useContext} from 'react';
  
  const defaultValue = {}
  
  const BreakpointContext = createContext(defaultValue);
  
  const BreakpointProvider = (props: React.PropsWithChildren<{queries: Record<string,string>}>) => {
    const [queryMatch, setQueryMatch] = useState({});
  
    useEffect(() => {
      const mediaQueryLists : Record<string,any> = {};
      const keys = Object.keys(props.queries);
      let isAttached = false;
  
      const handleQueryListener = () => {
        const updatedMatches = keys.reduce((acc: {[key: string]: any}, media) => {
          acc[media] = !!(mediaQueryLists[media] && mediaQueryLists[media].matches);
          return acc;
        }, {})
        setQueryMatch(updatedMatches)
      }
  
      if (window && window.matchMedia) {
        const matches: {[key: string]: any} = {};
        keys.forEach((media) => {
          if (typeof props.queries[media] === 'string') {
            mediaQueryLists[media] = window.matchMedia(props.queries[media]);
            matches[media] = mediaQueryLists[media].matches
          } else {
            matches[media] = false
          }
        });
        setQueryMatch(matches);
        isAttached = true;
        keys.forEach(media => {
          if(typeof props.queries[media] === 'string') {
            mediaQueryLists[media].addListener(handleQueryListener)
          }
        });
      }
  
      return () => {
        if(isAttached) {
          keys.forEach(media => {
            if(typeof props.queries[media] === 'string') {
              mediaQueryLists[media].removeListener(handleQueryListener)
            }
          });
        }
      }
    }, [props.queries]);
  
    return (
      <BreakpointContext.Provider value={queryMatch}>
        {props.children}
      </BreakpointContext.Provider>
    )
  
  }
  
  function useBreakpoint() {
    const context = useContext(BreakpointContext);
    if(context === defaultValue) {
      throw new Error('useBreakpoint must be used within BreakpointProvider');
    }
    return context;
  }
  export {useBreakpoint, BreakpointProvider};
  


// {
//   xs: '(min-width: 0)',
//   sm: '(min-width: 576px)',
//   md: '(min-width: 768px)',
//   lg: '(min-width: 992px)',
//   xl: '(min-width: 1200px)',
//   xxl:'(min-width: 1400px)'
// }

type KnownKeys<T> = {
  [K in keyof T]: any //string extends K ? never : number extends K ? never : K
} extends { 
  [_ in keyof T]: infer U 
} ? U : never;

type BreakpointCheck = {
  [key: string]: any
}

const bootstrapBreakpoints: Record<string, number> = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400
}

export const bootstrapQueries: Record<string, string> = {
  xs: "",
  sm: "",
  md: "",
  lg: "",
  xl: "",
  xxl: ""
};

Object.keys(bootstrapBreakpoints).forEach((value, index, array) => {
  const keys = Object.keys(bootstrapBreakpoints);
  if (index === 0) {
    bootstrapQueries[value] = '(max-width: ' + bootstrapBreakpoints[keys[index + 1]] + 'px)';
  } else if (index === array.length - 1) {
    bootstrapQueries[value] = '(min-width: ' + bootstrapBreakpoints[keys[index]] + 'px)';
  } else {
    bootstrapQueries[value] = '(min-width: ' + bootstrapBreakpoints[keys[index]] + 'px) and (max-width: ' + (bootstrapBreakpoints[keys[index + 1]] - 0.02) + 'px)';
  }
})

export function is<BPC extends BreakpointCheck, BP extends KnownKeys<BPC>>(breakpoint: BP, breakpointQueries: BPC): boolean {
  return(breakpointQueries[breakpoint]);
}

export function larger<BPC extends BreakpointCheck, BP extends KnownKeys<BPC>>(breakpoint: BP, breakpointQueries: BPC, orEqual: boolean = false): boolean {
  const keys = Object.keys(breakpointQueries);
  const breakpointIndex = keys.indexOf(breakpoint as string);
  let activeBreakpointIndex = 9999;

  keys.forEach((value, index) => {
    if (breakpointQueries[value]) activeBreakpointIndex = index;
  });

  return( breakpointIndex < activeBreakpointIndex || ( orEqual ? breakpointIndex === activeBreakpointIndex : false ) );
}

export function smaller<BPC extends BreakpointCheck, BP extends KnownKeys<BPC>>(breakpoint: BP, breakpointQueries: BPC, orEqual: boolean = false): boolean {
  return(!larger(breakpoint, breakpointQueries, !orEqual))
}
import React, {PropsWithChildren} from "react";
import "./style.scss"

export type CarouselProps = {firstName: string, lastName: string, position: string, from: number, img: string, email?: string} | undefined

export function ProfileCarousel(props: PropsWithChildren<{ items: CarouselProps[], itemsPerRow: number }>): JSX.Element {
    
    return  <div
                className="profile-carousel"
                style={{gridTemplate: `repeat(${ Math.max(1, Math.ceil((props.items.length / props.itemsPerRow) || 1) ) }, 1fr) / repeat(${props.itemsPerRow}, 1fr)`}}
            >
                {props.items.map((item, i) => 
                    item === undefined ? <React.Fragment key={i}>{ProfileCarouselItem(undefined)}</React.Fragment> :
                    <ProfileCarouselItem 
                        key={i + "_" + item.firstName + item.lastName}
                        firstName={item.firstName}
                        lastName={item.lastName}
                        position={item.position} 
                        from={item.from} 
                        img={item.img}
                        email={item.email}
                    />
                )}
            </div>
}

export function ProfileCarouselItem(props: CarouselProps): JSX.Element {  
    
    if (props === undefined) {
        return  <div className="profile-carousel-item loading" style={{position: "relative"}}>
                    <div className="profile-carousel-img rounded-circle overflow-hidden position-relative mx-auto mb-4">
                        {/* &nbsp; */}
                    </div>
                    <div className="profile-carousel-description">
                        <p className="h5 mb-0">&nbsp;</p>
                        <p className="mb-0 fw-bold">&nbsp;</p>
                        <span>&nbsp;</span>
                    </div>
                </div>
    }

    return  <div className="profile-carousel-item no-hover" style={{position: "relative"}}>
                <div className="profile-carousel-img" style={{
                    backgroundImage: `url("${props.img}")`
                }}>
                    <span className="visually-hidden">Foto von {props.firstName} {props.lastName}</span>
                </div>
                <div className="profile-carousel-description">
                    <p className="h5 mb-0">{props.firstName} {props.lastName}</p>
                    <p className="mb-0 fw-bold">{props.position}{props.from !== 0 && <><br/><span className="text-muted">seit {props.from}</span></>}</p>
                    {props.email && <a href={"mailto:" + props.email + "@unisono-alumni.at"}>{props.email + "@unisono-alumni.at"}</a>}
                </div>
            </div>
}
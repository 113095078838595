import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import styles from "./styles.module.scss"
import { sections } from "../../controllers/App";
import { Link } from "react-router-dom";
import classNames from "classnames";

export default function Footer(props: {dark?: boolean}) : JSX.Element {

    const footerCX = classNames({
        [styles.footer]: true,
        [styles.dark]: !!props.dark
    })

    const footerSmallCX = classNames({
        'text-white-50': !!props.dark,
        'text-black-50': !props.dark,
        'mb-0 small': true
    })

    return  <footer className={footerCX}>
                <Container>
                <Row>
                    <Col md="4" style={{margin: "1rem 0"}}>
                    <h6>UNISONO Alumni Club</h6>
                    <div className="d-flex flex-column align-items-start">
                        { sections.map(s => <Link to={s.path || "/" + s.id} className="nav-item nav-link" key={s.path || "/" + s.id}>{s.display}</Link>)}
                    </div>
                    </Col>
                    <Col md="4" style={{margin: "1rem 0"}}>
                    <h6>Rechtliches</h6>
                    <div className="d-flex flex-column align-items-start">
                        <Link to="/legal/statutes" className="nav-item nav-link">Statuten</Link>
                        <Link to="/legal/privacy" className="nav-item nav-link">Datenschutz</Link>
                        <Link to="/legal/imprint" className="nav-item nav-link">Impressum</Link>
                    </div>
                    </Col>
                    <Col md="4" style={{margin: "1rem 0"}}>
                    <h6>Verein</h6>
                    <div className="d-flex flex-column align-items-start">
                        <a href="https://club.unisono-alumni.at/sign-up" target="_blank" className="nav-item nav-link" rel="noreferrer">Mitglied werden</a>
                        <a href="https://club.unisono-alumni.at/sign-in" target="_blank" className="nav-item nav-link" rel="noreferrer">Mitgliedsbereich</a>
                        {/* <Link to="/circle" className="nav-item nav-link">Circle</Link> */}
                    </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                    <p className={footerSmallCX}>Made with Love by Fabian Eisenhut.</p>
                    <p className={footerSmallCX}>&copy; 2018 – 2022 UNISONO Alumni Club</p>
                    </Col>
                </Row>
                </Container>
            </footer>
}
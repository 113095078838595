import Button from "react-bootstrap/esm/Button"
import Card from "react-bootstrap/esm/Card"
import Col from "react-bootstrap/esm/Col"
import Container from "react-bootstrap/esm/Container"
import Row from "react-bootstrap/esm/Row"
import { SectionProps } from "."
import useNavSpy from "../../hooks/useNavSpy"
import "./styles.scss"

export default function MembershipSection(props: SectionProps) : JSX.Element {

    const membershipRef = useNavSpy("membership", props.toRef)

    return  <div id="membership" ref={membershipRef}>
                <Container>
                    <Row>
                        <Col lg="4" className="d-flex align-items-stretch">
                            <Card className="p-0 m-2" >
                                <Card.Body>
                                    <Card.Title>Jung-Alumni</Card.Title>
                                    <p className="lead">Unsere Mitglieder unter 18 Jahren sind von der Zahlung eines Mitgliedbeitrags befreit.</p>
                                </Card.Body>
                                <Card.Footer className="text-center py-3">
                                    <Button variant="nav" href="https://club.unisono-alumni.at/sign-up">Mitglied werden</Button>
                                    <p className="small text-black-50 my-2">Kein Mitgliedsbeitrag</p>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col lg="4" className="d-flex align-items-stretch">
                            <Card as={Col} className="p-0 m-2">
                                <Card.Body>
                                    <Card.Title>Ermäßigt</Card.Title>
                                    <p className="lead">Studierende, Präsenz- und Zivildiener können eine 50%ige Ermäßigung in Anspruch nehmen.</p>                    
                                </Card.Body>
                                <Card.Footer className="text-center py-3">
                                    <Button variant="nav" href="https://club.unisono-alumni.at/sign-up">Mitglied werden</Button>
                                    <p className="small text-black-50 my-2">15 € im Jahr</p>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col lg="4" className="d-flex align-items-stretch">
                            <Card as={Col} className="p-0 m-2">
                                <Card.Body>
                                    <Card.Title>Alumni</Card.Title>
                                    <p className="lead">Die gängige Mitgliedschaft für ehemalige Wiener Sängerknaben und Absolvent:innen des ORGs.</p>
                                </Card.Body>
                                <Card.Footer className="text-center py-3">
                                    <Button variant="nav" href="https://club.unisono-alumni.at/sign-up">Mitglied werden</Button>
                                    <p className="small text-black-50 my-2">30 € im Jahr</p>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="small text-black-50 text-center py-4">
                                Neben einer <q>Alumni</q>-Mitgliedschaft, können auch ehrenamtliche Mitglieder im Backstage Chor aufgenommen werden. Nimm dafür bitte Kontakt mit Imma auf.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
}
import { RouteObject, useRoutes } from "react-router-dom"
import { sections } from "./controllers/App"
import Circle from "./controllers/Circle"
import Imprint from "./controllers/Imprint"
import OnePager from "./controllers/OnePager"
import Privacy from "./controllers/Privacy"
import Statutes from "./controllers/Statutes"

interface SitemapRoute extends RouteObject {
    name: string,
    path: string,
    children?: SitemapRoute[]
}

export type Sitemap = SitemapRoute[]

export default function SiteRoutes() : JSX.Element {

    const routes: SitemapRoute[] = [
        { path: "/", name: "Startseite", element: <OnePager sections={sections} /> },
        { path: "/index", name: "Startseite", element: <OnePager sections={sections} /> },
        { path: "/about", name: "Über UNISONO", element: <OnePager sections={sections} /> },
        { path: "/milestones", name: "Meilensteine", element: <OnePager sections={sections} /> },
        { path: "/team", name: "Unser Team", element: <OnePager sections={sections} /> },
        { path: "/faq", name: "Fragen & Antworten", element: <OnePager sections={sections} /> },
        { path: "/legal", name: "Legal", children: [
            { path: "/legal/statutes", name: "Statuten", element: <Statutes />},
            { path: "/legal/imprint", name: "Impressum", element: <Imprint />},
            { path: "/legal/privacy", name: "Datenschutz", element: <Privacy />}
        ] },
        { path: "/circle", name: "UNISONO Circle", element: <Circle /> }
    ]

    return useRoutes(routes) || <></>

}
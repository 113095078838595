import { useParallax } from "react-scroll-parallax"
import { SectionProps } from "."
import { viewportHeight } from "../../helper/viewportPixels"
import useNavSpy from "../../hooks/useNavSpy"
import "./styles.scss"

export default function SectionAbout(props: SectionProps) : JSX.Element {

    const aboutRef = useNavSpy("about", props.toRef)

    const textParallax = useParallax<HTMLDivElement>({
        opacity: [0, 1],
        translateY: [50, 0],
        startScroll: viewportHeight(100) * .5,
        endScroll: viewportHeight(100) * 1
    })

    return  <div id="about" ref={aboutRef}>
                <div className="mx-3" style={{maxWidth: "400px", width: "100%"}} ref={textParallax.ref}> {/* transform: "translateY(-25%)" */}
                    <p className="lead fw-bold">Der UNISONO Alumni Club ist ein Verein für ehemalige Wiener Sängerknaben und AbsolventInnen der Oberstufe der Wiener Sängerknaben.</p>
                    <p className="lead">Als eigenständiger Verein ist unser Ziel, AbgängerInnen der Institution wieder miteinander zu vernetzen. Dafür veranstalten wir Events – exklusiv für unsere Mitglieder.</p>
                </div>
            </div>
}
import React, { createRef, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import NavBar, { NavLinkProps, NavSpy } from "../../components/NavBar";
import styles from '../../assets/App.module.scss';
import Footer from "../../components/Footer";
import { sections } from "../App";

export type SectionProps = {toRef: React.RefObject<HTMLDivElement>}

export default function OnePager(props: {sections: (NavLinkProps & NavSpy)[]}): JSX.Element {

    const [firstRender, setFirstRender] = useState(true)

    const hash = useLocation().pathname


    useEffect(() => {
        if (firstRender) {
            setTimeout(() => {
                document.getElementById(hash.substring(1))?.scrollIntoView({behavior: "smooth"})
                setFirstRender(false)
            }, 500);
        }
    }, [firstRender, hash])

    return  <>
                <NavBar className={styles.mainNavbar} navLinks={sections} />
                <Helmet>
                    <meta name="theme-color" content="#E1DF47" />
                </Helmet>
                {
                    props.sections.map(section => {
                        return  <React.Fragment key={section.id}>
                                    <section.component toRef={section.toRef || createRef()} />
                                </React.Fragment>
                    })
                }
                <Footer />
            </>
}
import { Alert, Container } from "react-bootstrap";
import { SectionProps } from ".";
import { CarouselProps, ProfileCarousel } from "../../components/ProfileCarousel";
import useNavSpy from "../../hooks/useNavSpy";

import "./styles.scss";
import { larger, useBreakpoint } from "../../context/Breakpoint";
import { Axios, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { ProfileDto, ProfileJob } from "../../components/Data/ProfileDto";
import { Link } from "react-router-dom";

// const order = {
//     "Obmann": 1,
//     "Kassier": 2,
//     "Schriftführerin": 3,
//     "Technik": 4,
//     "Stellvertretende": 5
// }

export default function Team(props: SectionProps): JSX.Element {

    const ref = useNavSpy("team", props.toRef)

    const bp = useBreakpoint()
    const itemsPerRow = larger("md", bp, true) ? 4 : 2 //  larger("sm", bp) ? 2 : 1

    return  <section ref={ref} id="team">
                <Vorstand itemsPerRow={itemsPerRow} />
                <Circle itemsPerRow={itemsPerRow} />
            </section>
}

function Vorstand(props: {itemsPerRow: number}): JSX.Element {

    const [vorstand, setVorstand] = useState<CarouselProps[]>()

    useEffect(() => {

        const axios = new Axios({responseType: "json"})

        axios
            .get<any, AxiosResponse< string >>("https://api.unisono-alumni.at/api/v1/network/vorstand", {responseType: "text"})
            .then(response => {
                if (response.status === 200) {
                    
                    const items: CarouselProps[] = []

                    if (response.data !== undefined) {
                        const parsed = JSON.parse(response.data as string) as ProfileDto[]

                        parsed.forEach(item => {

                            const jobs = item.ehrenamt.filter(v => {
                                return v.institution === "UNISONO Alumni Club" && !v.endYear
                            })

                            const defaultJob: ProfileJob = {
                                types: [],
                                city: "Wien",
                                institution: "UNISONO Alumni Club",
                                name: "Vorstandsmitglied",
                                description: "",
                                startYear: 0
                            }

                            const currentJob = jobs.sort((a, b) => (a.startYear || 0) - (b.startYear || 0)).at(0) || defaultJob

                            items.push({
                                firstName: item.firstName,
                                lastName: item.lastName,
                                position: currentJob.name,
                                from: currentJob.startYear || 0,
                                img: "https://api.unisono-alumni.at/api/v1/network/" + item.id + "/image"
                            })
                        })

                        // items.sort((a, b) => {
                        //     const orderA = a?.position
                        //     return 0
                        // })
                        
                        setVorstand(items)
                    }
                } else {
                    console.warn("Error (NON 200):", response)
                    setVorstand([])
                }
            })
            .catch((e) => {
                console.warn("Error:", e)
                setVorstand([])
            })
    }, [])
    

    if (vorstand === undefined) {
        // Loading
        const loadingItems = Array.from( {length: 8}, () => undefined )
        return  <Container>
                    <h2 className="display-3 text-center my-4 py-4">Vorstand</h2>
                    <ProfileCarousel items={loadingItems} itemsPerRow={props.itemsPerRow} />
                </Container>
    } else if (vorstand.length === 0) {
        // Error
        return  <Container>
                    <Alert variant="warning">
                        <Alert.Heading>Fehler</Alert.Heading>
                        <p className="mb-0">Leider ist ein Fehler bei der Datenübertragung aufgetreten. Bitte laden Sie die Seite neu.</p>
                    </Alert>
                </Container>
    } else {
        return  <Container>
                    <h2 className="display-3 text-center my-4 py-4">Vorstand</h2>
                    <ProfileCarousel items={vorstand} itemsPerRow={props.itemsPerRow} />
                </Container>
    }
}

function Circle(props: {itemsPerRow: number}) : JSX.Element {

    const [circle, setCircle] = useState<CarouselProps[]>()
    const emptyItems = Array.from( {length: props.itemsPerRow}, (_, i) => undefined )

    useEffect(() => {
        const axios = new Axios({responseType: "json"})

        axios
            .get<any, AxiosResponse< string >>("https://api.unisono-alumni.at/api/v1/network/circle", {responseType: "text"})
            .then(response => {
                if (response.status === 200) {
                    
                    const items: CarouselProps[] = []

                    if (response.data !== undefined) {
                        const parsed = JSON.parse(response.data as string) as ProfileDto[]

                        parsed.forEach(item => {

                            const jobs = item.ehrenamt.filter(v => {
                                return v.institution === "UNISONO Alumni Club" && !v.endYear
                            })

                            const defaultJob: ProfileJob = {
                                types: [],
                                city: "Wien",
                                institution: "UNISONO Alumni Club",
                                name: "Circle Mitglied",
                                description: "",
                                startYear: 0
                            }

                            const currentJob = jobs.sort((a, b) => (a.startYear || 0) - (b.startYear || 0)).at(0) || defaultJob

                            items.push({
                                firstName: item.firstName,
                                lastName: item.lastName,
                                position: currentJob.name,
                                from: currentJob.startYear || 0,
                                img: "https://api.unisono-alumni.at/api/v1/network/" + item.id + "/image"
                            })
                        })

                        items.sort((a, b) => (a?.from || 0) - (b?.from || 0))
                        
                        setCircle(items)
                    }
                } else {
                    console.warn("Error (NON 200):", response)
                    setCircle([])
                }
            })
            .catch((e) => {
                console.warn("Error:", e)
                setCircle([])
            })
    }, [])

    return  <>{(!circle || circle.length > 0) && 
            <Container>
                <div className="text-center py-4 my-4">
                    <h2 className="display-3">UNISONO Circle</h2>
                    <p className="lead mt-4">Der UNISONO Circle besteht aus Mitgliedern, die sich bei UNISONO besonders engagieren.</p>
                    {/* <p className="my-4"><Link to="/circle">Mehr Informationen</Link></p> */}
                </div>
                {!circle && <ProfileCarousel items={emptyItems} itemsPerRow={props.itemsPerRow} />}
                {!!circle && circle.length > 0 && <ProfileCarousel items={circle} itemsPerRow={props.itemsPerRow} />}
            </Container>
            }</>
}
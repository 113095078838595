import { CSSProperties } from "react";
import { Container } from "react-bootstrap";
import { sections } from "../../controllers/App";
import NavBar from "../NavBar";


export default function Header(props: {title?: string, subtitle?: string, invert?: boolean, style?: CSSProperties, className?: string, classNameNav?:string, relative?: boolean}): JSX.Element {

    return  <header className={!!props.invert ? "bg-black text-light" : "bg-light"} style={{padding: "1rem 0"}}>
                <NavBar navLinks={sections} relative={props.relative} className={props.classNameNav} />
                {props.title &&
                <Container className={"py-4 " + (props.className || "")} style={props.style}>
                    { props.title && <h2 className="display-2">{props.title}</h2>}
                    { props.subtitle && <p className="display-6">{props.subtitle}</p>}
                </Container>
                }
            </header>
}
import "../assets/bootstrap-all.scss"

import { NavLinkProps, NavSpy } from "../components/NavBar";

import smoothscroll from 'smoothscroll-polyfill'
import { NavProvider } from "../context/NavProvider";
import { createRef } from "react";
import HomeHero from "./OnePager/HomeHero";
import SectionAbout from "./OnePager/SectionAbout";
// import OffersSection from "./OnePager/OffersSection";
// import PartnersSection from "./OnePager/PartnersSection";
import MembershipSection from "./OnePager/MembershipSection";
import FAQSection from "./OnePager/FAQSection";
import { ParallaxProvider } from "react-scroll-parallax"; 
import Team from "./OnePager/Team";
import { BreakpointProvider, bootstrapQueries } from "../context/Breakpoint";
import Milestones from "./OnePager/Milestones";
import { Helmet } from "react-helmet";
import SiteRoutes from "../routes";

smoothscroll.polyfill()

export const sections: (NavLinkProps & NavSpy)[] = [
  {id: "index", display: "Startseite", toRef: createRef<HTMLDivElement>(), component: HomeHero},
  {id: "about", display: "Über UNISONO", toRef: createRef<HTMLDivElement>(), component: SectionAbout},
  // {id: "offers", display: "Angebot", toRef: createRef<HTMLDivElement>(), component: OffersSection},
  // {id: "milestones", display: "Meilensteine", toRef: createRef<HTMLDivElement>(), component: Milestones},
  // {id: "partners", display: "Partner", toRef: createRef<HTMLDivElement>(), component: PartnersSection},
  {id: "team", display: "Unser Team", toRef: createRef<HTMLDivElement>(), component: Team},
  {id: "membership", display: "Mitgliedschaft", toRef: createRef<HTMLDivElement>(), component: MembershipSection},
  {id: "faq", display: "Fragen & Antworten", toRef: createRef<HTMLDivElement>(), component: FAQSection}
]

function App() {

  return  <NavProvider>
            <ParallaxProvider>
              <BreakpointProvider queries={bootstrapQueries}>
                  <Helmet>
                      <meta name="theme-color" content="#EAF4F7" />
                  </Helmet>
                  <SiteRoutes />
                  {/* <Routes>
                    {/* <Route path="/imprint" element={<Imprint />} />
                    <Route path="/statutes" element={<Statutes />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/circle" element={<Circle />} />
                    <Route path="*" element={<>
                      <OnePager sections={sections} />
                      </>}
                    /> 
                  </Routes> */}
              </BreakpointProvider>
            </ParallaxProvider>
          </NavProvider>
}

export default App;

import Col from "react-bootstrap/esm/Col"
import Container from "react-bootstrap/esm/Container"
import Row from "react-bootstrap/esm/Row"
import { Link } from "react-router-dom"
import { SectionProps } from "."
import FAQs from "../../components/FAQs/FAQs"
import useNavSpy from "../../hooks/useNavSpy"
import "./styles.scss"

export default function FAQSection(props: SectionProps) : JSX.Element {

    const faqRef = useNavSpy("faq", props.toRef)

    return  <div id="faq" ref={faqRef}>
                <Container>
                    <Row>
                        <Col>
                        <h2>Fragen &amp; Antworten</h2>
                        <p className="lead mb-4">Lies hier nach, welche Fragen häufig von anderen gestellt werden. Solltest Du mehr wissen wollen, <Link to="/imprint">melde Dich bei uns</Link>.</p>
                        <FAQs />
                        </Col>
                    </Row>
                </Container>
            </div>
}